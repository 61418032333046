<template>
  <div>
    <div class="agent-line-container">
      <span v-text="$t('agent_line')" /> :
      <div
        v-if="agentLines.length > 0"
        class="agent-line-list-container"
      >
        <span
          v-for="(item, index) in agentLines"
          :key="item.id"
        >
          <span
            class="agent-line-list"
            @click="loadAgent(item.id)"
            v-text="item.username"
          />
          <span
            v-if="agentLines.length - 1 !== index"
            v-text="`/`"
          />
        </span>
      </div>
    </div>
    <agent-overview
      :agent="agent"
    />
    <!-- Filter -->
    <b-card
      footer-tag="footer"
      class="mt-1 mb-2 card-filter-container"
    >
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="card-filter-item"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('account')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.account"
              autocomplete="off"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="card-filter-item mt-1"
        >
          <b-button
            variant="primary float-right"
            @click="loadData"
            v-text="$t('search')"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col
        sm="4"
        md="4"
        lg="4"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.grand_balance).toLocaleString()" />
          <span v-text="$t('grand_balance')" />
        </b-card>
      </b-col>
      <b-col
        sm="4"
        md="4"
        lg="4"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.grand_balance_of_child_agent).toLocaleString()" />
          <span v-text="$t('grand_balance_of_child_agent')" />
        </b-card>
      </b-col>
      <b-col
        sm="4"
        md="4"
        lg="4"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.grand_total_balance).toLocaleString()" />
          <span v-text="$t('grand_total_balance')" />
        </b-card>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      opacity="0.80"
      rounded="sm"
    >
      <b-card>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="users"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Account -->
            <template
              v-if="props.column.field === 'account'"
            >
              <a
                v-if="props.row.role !== 'member'"
                href="#"
                class="align-middle ml-50"
                @click="loadAgent(props.row.id)"
                v-text="props.row.account"
              />
              <span
                v-else
                v-text="props.row.account"
              />
            </template>
            <!-- Column: Balance -->
            <span
              v-else-if="props.column.field === 'balance'"
              v-text="Number(props.row.balance).toLocaleString()"
            />
            <!-- Column: Child Balance -->
            <span
              v-else-if="props.column.field === 'childs_balance'"
              v-text="Number(props.row.childs_balance).toLocaleString()"
            />
            <!-- Column: Total Balance -->
            <span
              v-else-if="props.column.field === 'total_balance'"
              v-text="Number(props.row.total_balance).toLocaleString()"
            />
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- Columns -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span v-text="$t(props.column.label)" />
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-0">
                <span class="text-nowrap">
                  <span v-text="$t('pagination_showing')" /> 1 <span v-text="$t('pagination_to')" />
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['50','100','200', '300']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> <span v-text="$t('pagination_of')" /> {{ props.total }}<span v-text="$t('pagination_entries')" /> </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-2"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'
import AgentOverview from '@/components/agent-overview/AgentOverview.vue'

import {
  BOverlay,
  BCard,
  BPagination,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BFormSelect,
  BFormInput,
} from 'bootstrap-vue'

import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'

import Account from '@/models/Account'

import { EventBus } from '../../../eventbus'

export default {
  name: 'ReportBalance',
  components: {
    BOverlay,
    BCard,
    BPagination,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    VueGoodTable,
    BButton,
    BFormSelect,
    BFormInput,
    AgentOverview,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 50,
      columns: [],
      users: [],
      report: {
        grand_balance: 0,
        grand_balance_of_child_agent: 0,
        grand_total_balance: 0,
      },
      agent: {
        id: '',
        username: '',
        currency: '',
        commission: 0,
        share: 0,
      },
      loading: false,
      filter: {
        account: typeof this.$route.query.account !== 'undefined' ? this.$route.query.account : '',
      },
      agentLines: [],
      loadAgent(id) {
        this.filter.agent = id
        this.filter.account = ''
        this.loadData(1)
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      const searchParams = {}
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })
      this.$router.replace({ query: searchParams })
      const response = await Account.balanceList(searchParams)
      this.users = response.data.data
      this.report = response.data.meta.report
      this.agent = response.data.meta.agent
      if (this.agent.role === 'agent') {
        this.columns = [
          {
            label: 'account',
            field: 'account',
            type: 'text',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'shares',
            field: 'shares',
            type: 'number',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'commission',
            field: 'commission',
            type: 'number',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'balance',
            field: 'balance',
            type: 'number',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'total_balance',
            field: 'total_balance',
            type: 'number',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
        ]
      } else {
        this.columns = [
          {
            label: 'account',
            field: 'account',
            type: 'text',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'shares',
            field: 'shares',
            type: 'number',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'commission',
            field: 'commission',
            type: 'number',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'balance',
            field: 'balance',
            type: 'number',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'balance_of_child_agent',
            field: 'childs_balance',
            type: 'number',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'total_balance',
            field: 'total_balance',
            type: 'number',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
        ]
      }
      this.agentLines = response.data.meta.agent_lines
      EventBus.$emit('getMyUpdatedUserData') // Todo: Need to refactor
      this.loading = false
    },
  },
}
</script>

<style>
</style>
